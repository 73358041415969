<template>

    <div class="h-screen"
         :class="{'mobile-wrapper' : isMobileLayout}"
         :id="terminal.colorscheme">

        <router-view />
        <printer></printer>

        <notification />
        <div id="modal"></div>
        <div id="mobile-modal"></div>
    </div>
</template>


<script>

if (process.env.VUE_APP_FEEDBACK == 1) {
    window.onUsersnapCXLoad = function(api) {
        api.init();
    }

    var script = document.createElement('script');
    script.async = 1;
    script.src = 'https://widget.usersnap.com/load/0c622e9f-0e40-44c7-bea9-5600f82fb172?onload=onUsersnapCXLoad';
    document.getElementsByTagName('head')[0].appendChild(script);
}

import { mapActions } from 'vuex';

// components
import Notification from './components/Notification.vue';
import Printer from './components/Printer';

export default {
    components: {
        Printer,
        Notification,
    },

    mounted() {
        this.$watch(
            () => this.isMobileLayout,
            (isMobileLayout) => {
                if (isMobileLayout) {
                    document.documentElement.classList.add("screen-table")
                } else {
                    document.documentElement.classList.remove("screen-table")
                }
            },
            { immediate: true }
        )

        document.addEventListener('DOMContentLoaded', () => {
            document.documentElement.classList.add(`terminal_${this.terminal.colorscheme}`);
        })
    },

  methods: {
        ...mapActions({
            logoutAction: 'auth/logout',
        }),

        async logout () {
            await this.logoutAction();

            this.$router.replace({ name: 'login' });
        },
    },
    computed: {
        isMobileLayout () {
            return ['mobiledirect', 'mobileordering', 'mobileordering_buttons']
                .includes(this.terminal.layout);
        },

        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
    },
};

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


</script>


<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #1A1A1A;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #1A1A1A;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

#pos .department.active {
    background-color: #eb5e1d;
    color: #ffffff;
}

/*#pos .item {*/
/*    background-color: #f5f5f5;*/
/*}*/
/*#pos .button {*/
/*    background-color: #f5f5f5;*/
/*}*/

/* buttons */
.btn-primary {
    background: #eb5e1d;
    color: #fff
}

.border-highlight {
    border-color: #eb5e1d;
}
</style>
